<template>
  <v-layout>
    <base-list-view
      title="Шаблоны чек-листов"
      @onRefresh="init"
      :dataSource="dataSource"
      :headers="headers"
      :loading="loading"
      pageEditName="CheckListTemplateView"
      :showDelete="false"
      :notShowActions="['add']"
    >
    </base-list-view>
  </v-layout>
</template>

<script>
import { CheckListObjectTypes } from "../../data/enums";
import BaseListView from "../../layouts/BaseListView.vue";
import CheckListService from "../../services/CheckListService";

export default {
  name: "view-CheckListTemplates",
  components: { BaseListView },
  data: () => {
    return {
      apiService: CheckListService,
      loading: true,
      headers: [
        { text: "Название", value: "Name" },
        {
          text: "Показатель оценки качества",
          value: "IndicatorsNames",
        },
        {
          text: "Тип",
          value: "Type",
          dataType: "enum",
          options: CheckListObjectTypes,
        },
      ],
      dataSource: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.loading = true;
        const data = (await CheckListService.get([], [], true)).data;
        for (const value of data)
          value.IndicatorsNames = value.Indicators
            ? value.Indicators.map((ind) => ind.Name).join(", ")
            : null;
        this.dataSource = data;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped></style>
